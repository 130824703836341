import { formatDate } from './app/util';

export {};

declare global {
  interface Array<T> {
    remove(o: T): Array<T>;
  }
}

// eslint-disable-next-line
Array.prototype.remove = function (o) {
  this.splice(this.indexOf(o), 1);
  return this;
};

// eslint-disable-next-line
Date.prototype.toJSON = function () {
  return formatDate(this) ?? '';
};
